<template>
  <div class="footer">
    <div class="floating_btn">
      <a target="_blank"
        href="https://api.whatsapp.com/send?phone=5535997012939&amp;text=Olá%20Auto%20Socorro%20Prime,%20gostaria%20de%20um%20orçamento.">
        <div class="contact_icon">
          <img src="@/assets/whatsapp-32.svg" alt="" width="32" height="32">
        </div>
      </a>
      <p class="text_icon">Entre em contato</p>
    </div>
    <div class="footer-container">
      <div class="footer-item-container item-logo"><a href="https://moovit-87ad89.webflow.io/" class="w-inline-block"><img
            src="@/assets/logo.webp" loading="lazy" width="115" height="101" alt="Banner logo"
            class="footer-logo-image"></a>
        <a target="_blank" href="#" class="footer-links"><img src="@/assets/local.svg" alt="" width="16"
            height="16">&nbsp;<span>Perdões, Minas Gerais</span></a>
        <a target="_blank" href="tel:35997012939" class="footer-links"><img src="@/assets/phone-32.svg" alt="" width="16"
            height="16">&nbsp;<span>(35) 9 9701-2939</span></a>
        <a target="_blank" href="mailto:contato@autosocorroprime.com.br" class="footer-links"><img
            src="@/assets/envelope-32.svg" alt="" width="16"
            height="16">&nbsp;<span>contato@autosocorroprime.com.br</span></a>
        <div class="footer-socials-link">
          <h4 class="footer-lists-title">REDES SOCIAIS</h4>
          <a target="_blank" href="https://www.instagram.com/autosocorroprime" class="footer-social-link"><img
              src="@/assets/instagram-logo-32.svg" alt="" width="32" height="32"></a>&nbsp;
          <a target="_blank" href="https://www.facebook.com/autosocorroprime" class="footer-social-link"><img
              src="@/assets/facebook-logo-32.svg" alt="" width="32" height="32"></a>
        </div>
      </div>
      <div class="footer-item-container">
        <div class="footer-lists">
          <h4 class="footer-lists-title">INSTITUCIONAL</h4>
          <a href="#" class="footer-links">Home</a>
          <a href="#" class="footer-links">Sobre</a>
          <a href="#" class="footer-links">Contato</a>
        </div>
      </div>
      <div class="footer-item-container">
        <div class="footer-lists">
          <h4 class="footer-lists-title">SERVIÇOS</h4>
          <a href="#" class="footer-links">Reboque</a>
          <a href="#" class="footer-links">Troca de pneus</a>
          <a href="#" class="footer-links">Carga na Bateria</a>
          <a href="#" class="footer-links">Pane Seca<br></a>
        </div>
      </div>
    </div>
    <!-- <div class="powered-webflow">
    <div class="white-text-align-left">Powered by <a href="https://webflow.com/">Webflow</a></div>
  </div> -->
  </div>
</template>
<style lang="scss" scoped>
a {
  text-decoration: none;
}

.floating_btn {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

@keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

.contact_icon {
  background-color: #42db87;
  color: #fff;
  width: 60px;
  height: 60px;
  font-size: 30px;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translatey(0px);
  animation: pulse 1.5s infinite;
  box-shadow: 0 0 0 0 #42db87;
  -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  font-weight: normal;
  font-family: sans-serif;
  text-decoration: none !important;
  transition: all 300ms ease-in-out;

  img {
    margin: 0 !important;
  }
}

.text_icon {
  margin-top: 8px;
  color: #707070;
  font-size: 13px;
}

.footer {
  background-color: #000;
  padding-left: 15px;
  padding-right: 15px;

  * {
    margin: .5rem 0 !important;
    text-align: left !important;
  }
}

.footer-lists-title {
  color: #fff;
  margin: 1rem 0 !important;
}

div {
  display: block;
}

@media only screen and (max-width: 600px) {
  .footer-container {
    padding-bottom: 60px;
    padding-left: 0;
    padding-right: 0;
  }

  .footer-container div {
    padding: 0 !important;
  }
}

.footer-links {
  width: 100%;
  margin: 0 !important;
}

.footer-container {
  padding-top: 80px;
  padding-bottom: 80px;
  display: grid;
  grid-template-columns: repeat(3, calc(80%/3));

  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 95%);
    column-gap: 0;
  }

  column-gap: 10px;
  justify-content: center;

  div {
    width: 100%;
    padding: 2rem !important;
  }
}

.footer-item-container.item-logo {
  padding-right: 20px;
}

.footer-item-container {
  width: 18%;
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

.footer-logo-image {
  max-width: 150px !important;
  width: auto;
  height: auto;
}

img {
  max-width: 100%;
}

img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

img {
  border: 0;
}

.address {
  color: #9fa3a5;
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 400;
}

.footer-links {
  opacity: .6;
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  transition: opacity .3s;
  display: block;
  text-align: left;
}

.buy-now-fixed {
  z-index: 1000;
  color: #131c21;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  justify-content: flex-start;
  align-items: center;
  padding: 6px 8px 6px 6px;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  display: flex;
  position: fixed;
  top: auto;
  bottom: 12px;
  left: auto;
  right: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
}

.view-our-templates {
  z-index: 1000;
  width: 143px;
  color: #131c21;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  justify-content: flex-start;
  align-items: center;
  padding: 6px 8px 6px 6px;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  display: flex;
  position: fixed;
  top: auto;
  bottom: 48px;
  left: auto;
  right: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
}

/* .hire-button {
    z-index: 1000;
    color: #fff;
    background-color: #1c276d;
    border: 1px solid #1c276d;
    border-radius: 4px;
    padding: 8px;
    font-family: Arial,Helvetica,Helvetica,sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 1em;
    transition-property: none;
    display: flex;
    position: fixed;
    top: auto;
    bottom: 12px;
    left: 12px;
    right: auto;
} */
.footer-social-link {
  color: #fff;
  background-color: transparent;
  margin-right: 15px;
  font-family: Icon Font, sans-serif;
  font-size: 17px;
  line-height: 1;
  text-decoration: none;
  transition: color .3s;
}</style>
