<template>
  <Nav />
  <section class="r-view"><router-view /></section>

  <Footer />
</template>

<style lang="scss">
* {
  text-decoration: none;
  list-style: none;
  text-align: center !important;
  margin: 0 auto !important;
  box-sizing: border-box !important;
  max-width: 100vw !important;
}

.r-view {
  margin: 3rem 0 !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.btn-banner {
  position: relative;
  margin: 1rem 5rem !important;
    background: #f15806cc;
    box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 10px #f5650c, 0 0 20px #f5650c, 0 0 30px #f5650c, 0 0 40px #f5650c, 0 0 50px #f5650c;
    // border-collapse: separate;
  color: #fff;
  opacity: 1;
  padding: 10px 30px 10px 30px !important;
  border-radius: 99px;
  width: 20%;

  @media only screen and (max-width: 600px) {
    max-width: 90% !important;
    margin: 0 !important;
  }
}
</style>
<script>
// @ is an alias to /src
import Footer from '@/components/Footer.vue'
import Nav from '@/components/Nav.vue'

export default {
  name: 'Home',
  components: {
    Nav,
    Footer
  }
}

</script>
